import { observable, makeAutoObservable, action, runInAction } from "mobx";
import { createContext } from "react";
import axios from "axios";
import { message } from "antd";

import { showErrorNotification } from "../utils/notification";
import {
  Coords, IntervalGamePopulated, MomentResponseWithSize, Section, TeamAdsWithId, UniqueUser,
} from "../shared/interfaces";

import { AppServiceInstance } from "./app";
import { getGameDataByIdRequest, getTeamAdsByTeamId } from "../axios/routes/game";
import { addUserRequest, getLastSession } from "../axios/routes/user";
import { Routes } from "../utils/routes";
import {
  StorageCropData, StorageSelectedMomentosData,
  ShownTipsData, SelectedMomentosData,
} from "../interfaces/moment";

import LogoIcon from "../assets/images/LogoHeader.png";
import { COLORS } from "../components/styled";
import { getAllSections, getSpecialSections, fetchSectionsRowsSeats } from "../axios/routes/moment";
import { getSuiteName, prepareSectionRowSeat } from "../utils/suite";
import { ImageCropData, ImageLoadResult, loadImage, releaseCanvas } from "../utils/sharedFunctions";
import { LastPlace } from "../utils/lastPlaces";

enum SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM {
  GAME="/selectedGame",
  SECTION="/selectedSection",
  SPECIAL_SECTION="/selectedSpecialSection",
  ROW="/selectedRow",
  SEAT="/selectedSeat",
  SUITE="/selectedSuite",
  VERIFY_LAST_NAME="/selectedVerifyLastName",
  FIRST_NAME="/selectedFirstName",
  LAST_NAME="/selectedLastName",
  EMAIL="/selectedEmail",
  PHONE="/selectedPhone",
  ZIP_CODE="/selectedZipCode",
  BIRTHDATE="/selectedBirthDate",
  COUNTRY="/selectedCountry",
  MOMENT="/selectedMoment",
  SELECTED_MOMENTO="/selectedSlide",
  CROP_DATA="/imageCropData",
  SHOWN_TIPS="/shownTips",
  SELECTED_MOMENTOS="/selectedImages",
  SELECTED_PLACE_DATA="/selectedPlaceData",
  SHOW_MOMENT_CAPTION="/selectedShowMomentCaption",
  SECTION_IS_SPECIAL="/isSpecialSection",
  SECTION_TYPE="/specialType",
  PLACE_X="/selectedPlaceX",
  PLACE_Y="/selectedPlaceY",
  PLACE_WIDTH="/selectedPlaceWidth",
  PLACE_HEIGHT="/selectedPlaceHeight",
  LAST_PLACES="/lastPlaces",
}

export const DEFAULT_SIZE = 500;
export const MAX_SPECIAL_SECTION_WIDTH = 4000;

class StorageService {
  @observable selectedGame: string = null;

  @observable selectedGameData: IntervalGamePopulated = null;

  @observable specialSections: Section[] = [];

  @observable allSuites: Section[] = [];

  @observable specialSectionsListLoading = true;

  @observable selectedSection: string = null;

  @observable selectedSpecialSection: string = null;

  @observable selectedRow: string = null;

  @observable selectedSeat: string = null;

  @observable selectedSuite: string = null;

  @observable showSuites = true;

  @observable selectedSectionIsSpecial: boolean = null;

  @observable selectedSectionType: string = null;

  @observable selectedPlaceX: number = null;

  @observable selectedPlaceY: number = null;

  @observable selectedPlaceWidth: number = null;

  @observable selectedPlaceHeight: number = null;

  @observable selectedFirstName: string = null;

  @observable selectedLastName: string = null;

  @observable selectedEmail: string = null;

  @observable selectedPhone: string = null;

  @observable selectedZipCode: string = null;

  @observable selectedBirthDate: string = null;

  @observable selectedCountry: string = null;

  @observable selectedShowMomentCaption = true;

  @observable storageCropData: StorageCropData = null;

  @observable storageSelectedPlaceData: StorageCropData = null;

  @observable storageSelectedMomentosData: StorageSelectedMomentosData = null;

  @observable selectedNotMyDevice: boolean = null;

  @observable selectedSponsorOptIn: boolean = null;

  // TODO: change to actual time
  @observable selectedMoment: string = null;

  @observable selectedMomento: number = null;

  @observable shownTipsData: ShownTipsData = null;

  @observable availableSections: Section[] = [];

  @observable stadiumMap: Map<string, Map<string, Set<string>>> = new Map();

  @observable sectionsListLoading = false;

  // cached team logos
  @observable team1logoImage: HTMLImageElement | null = null;

  @observable team1logoDataUrl: string | null = null;

  @observable team1logoSrc: string | null = null;

  @observable team2logoImage: HTMLImageElement | null = null;

  @observable team2logoDataUrl: string | null = null;

  @observable team2logoSrc: string | null = null;

  @observable mainLogoImage: HTMLImageElement | null = null;

  @observable team1PaywallImage: HTMLImageElement | null = null;

  @observable team1PaywallDataUrl: string | null = null;

  @observable team1PaywallSrc: string | null = null;

  @observable team1TicketAdImage: HTMLImageElement | null = null;

  @observable team1TicketAdDataUrl: string | null = null;

  @observable team1TicketAdSrc: string | null = null;

  @observable team1PopupImage: HTMLImageElement | null = null;

  @observable team1PopupDataUrl: string | null = null;

  @observable team1PopupSrc: string | null = null;

  @observable showPopupImage = false;

  @observable popupImageWasShown = false;

  // team colors
  @observable team1Color: string | null = COLORS.GREEN_DARK;

  @observable team2Color: string | null = COLORS.GREY_DARK;

  // cached team Advertisments
  @observable teamAds: TeamAdsWithId | null = null;

  @observable team1SponsorLogoImage: HTMLImageElement | null = null;

  @observable team1SponsorLogoDataUrl: string | null = null;

  @observable team1SponsorLogoSrc: string | null = null;

  @observable showAdAfterContactInfo = true;

  @observable adAfterContactInfoBlob: Blob | null = null;

  @observable adAfterContactInfoSrc: string | null = null;

  // indicates that at least single team dictates to hide purchase
  @observable hidePurchaseActions = false;

  @observable hideDownloadActions = false;

  // indicates that home team should not show Show Order Printed Photo button
  @observable showOrderPrintedPhoto = false;

  // last places information
  @observable lastPlaces: LastPlace[] = [];

  // store moments camera data to access it
  public defaultZoomWidth: number = null;

  public defaultZoomHeight: number = null;

  public centerCoords: Coords = null;

  public offsetY: number = null;

  @observable resumeSessionDialogWasShown = false;

  // verify place
  @observable selectedVerifyLastName: string = null;

  // we should show user details first
  @observable showUserDetailsFirst = false;

  // we should show half of the image blurred
  @observable blurImage = false;

  constructor() {
    makeAutoObservable(this);

    const { hostname, search } = window.location;
    const hostnamePrefix = hostname?.substring(0, hostname.indexOf(".")).toLowerCase();
    this.showUserDetailsFirst = hostnamePrefix === "tigers";
    this.blurImage = hostnamePrefix === "amex" || search?.indexOf("blurred=true") !== -1;

    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.GAME);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SPECIAL_SECTION);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.ROW);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SEAT);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SUITE);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.FIRST_NAME);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.LAST_NAME);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.EMAIL);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PHONE);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.ZIP_CODE);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.BIRTHDATE);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.COUNTRY);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.VERIFY_LAST_NAME);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.MOMENT);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_MOMENTO);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.CROP_DATA);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_MOMENTOS);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_PLACE_DATA);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SHOWN_TIPS);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION_IS_SPECIAL);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION_TYPE);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_X);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_Y);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_WIDTH);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_HEIGHT);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.LAST_PLACES);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SHOW_MOMENT_CAPTION);
  }

  @action selectGame = async (game: IntervalGamePopulated) => {
    this.moveToTheNextPage(Routes.GAMES);
    this.selectedGame = game._id;

    if (game.lastMomentTeam1Score != null) { // This checks if the value exists (including 0) and is not undefined or null
      this.clearCachedImages();

      this.showAdAfterContactInfo = true;

      await this.fetchGameData();
      if (!this.popupImageWasShown) {
        this.showPopupImage = true;
      }

      this.saveDataToWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.GAME, this.selectedGame);
    } else {
    // If there's no lastMomentPeriodType for the selected game, route to "signup" page
      AppServiceInstance.history.push(Routes.SIGNUP);
    }
  };

  @action loadGameData = async (gameId: string) => {
    this.clearCachedImages();

    await this.fetchGameData(gameId);
  };

  // update local storage
  @action updateSavedDataFromRestoredSession = (
    selectedGame: string,
    notMyDevice: boolean,
    SponsorOptIn: boolean,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    zipCode: string,
    birthDate: string,
    country: string,
    section: string,
    row: string,
    seat: string,
    saveUserDetails = true,
  ) => {
    this.selectedGame = selectedGame;
    this.saveDataToWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.GAME, this.selectedGame);

    if (section?.startsWith("Special: ")) {
      const sectionName = section.substring(9);
      this.saveUsersPlace(true, sectionName, "", "", "", "");

      const partsY = (row || "").split("-");
      const partsX = (seat || "").split("-");

      if (partsY.length > 1 && partsX.length > 1) {
        this.saveUsersPlaceRect(Number(partsX[0]), Number(partsY[0]), Number(partsX[1]), Number(partsY[1]));
      }
    } else {
      const suite = getSuiteName(section);
      if (suite) {
        this.saveUsersPlace(false, "", "", "", this.showSuites ? suite : "");
      } else {
        this.saveUsersPlace(false, section, row, seat, "");
      }
    }

    if (saveUserDetails) {
      this.selectedFirstName = firstName;
      this.selectedLastName = lastName;
      this.selectedEmail = email;
      this.selectedPhone = phone;
      this.selectedZipCode = zipCode;
      this.selectedBirthDate = birthDate;
      this.selectedCountry = country;

      this.saveUserDetailsToStorage(notMyDevice, SponsorOptIn);
    }

    // use provided email for current session only if we do not have email
    if (!saveUserDetails && email && !this.selectedEmail) {
      this.selectedEmail = email;
    }
  }

  @action public updateVerifyLastName = (lastName: string) => {
    this.selectedVerifyLastName = lastName;

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.VERIFY_LAST_NAME,
      this.selectedVerifyLastName,
    );
  }

  // update local storage
  @action public savePreviousSessionToStorage = (previousSession: UniqueUser) => {
    this.updateSavedDataFromRestoredSession(
      previousSession.game,
      previousSession.notMyDevice,
      previousSession.SponsorOptIn,
      previousSession.firstName,
      previousSession.lastName,
      previousSession.email,
      previousSession.phoneNumber,
      previousSession.zipCode,
      previousSession.birthDate,
      previousSession.country,
      previousSession.section,
      previousSession.row,
      previousSession.seat,
    );
  }

  // not used: load last game
  @action public restorePreviousSession = async () => {
    if (!this.selectedEmail) {
      return;
    }

    const lastSession = await getLastSession(this.selectedEmail);
    if (!lastSession || !lastSession.game) {
      return;
    }

    await this.loadGameData(lastSession.game);
  }

  @action public saveUsersPlace = (
    isSpecialSection: boolean,
    section: string,
    row: string,
    seat: string,
    suite: string,
    sectionType?: string,
  ) => {
    this.selectedSectionIsSpecial = isSpecialSection;

    if (this.selectedSectionIsSpecial) {
      this.selectedSpecialSection = section;
      this.selectedSectionType = sectionType;
    } else {
      this.selectedSection = section;
      this.selectedRow = row;
      this.selectedSeat = seat;
      this.selectedSuite = suite;
    }

    if (this.selectedSectionIsSpecial) {
      this.saveDataToWorkerStorage(
        SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SPECIAL_SECTION, this.selectedSpecialSection,
      );
      this.saveDataToWorkerStorage(
        SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION_TYPE, this.selectedSectionType,
      );
      this.saveDataToWorkerStorage(
        SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION_IS_SPECIAL, "Yes",
      );
    } else {
      this.saveDataToWorkerStorage(
        SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION, this.selectedSection,
      );

      this.saveDataToWorkerStorage(
        SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.ROW, this.selectedRow,
      );
      this.saveDataToWorkerStorage(
        SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SEAT, this.selectedSeat,
      );
      this.saveDataToWorkerStorage(
        SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SUITE, this.selectedSuite,
      );
      this.saveDataToWorkerStorage(
        SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION_IS_SPECIAL, "No",
      );
    }
  }

  @action public saveUsersPlaceRect = (
    placeX: number,
    placeY: number,
    placeWidth: number,
    placeHeight: number,
  ) => {
    this.selectedPlaceX = placeX;
    this.selectedPlaceY = placeY;
    this.selectedPlaceWidth = placeWidth;
    this.selectedPlaceHeight = placeHeight;

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_X, `${this.selectedPlaceX}`,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_Y, `${this.selectedPlaceY}`,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_WIDTH, `${this.selectedPlaceWidth}`,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_HEIGHT, `${this.selectedPlaceHeight}`,
    );
  }

  @action public saveUserDetails = async (notMyDevice: boolean, firstName: string,
    lastName: string, email: string, phone: string, zipCode: string, birthDate: string, country: string, SponsorOptIn: boolean) => {
    this.selectedFirstName = firstName;
    this.selectedLastName = lastName;
    this.selectedEmail = email;
    this.selectedPhone = phone;
    this.selectedZipCode = zipCode;
    this.selectedBirthDate = birthDate;
    this.selectedCountry = country;

    // store only in memory
    this.selectedNotMyDevice = notMyDevice;
    this.selectedSponsorOptIn = SponsorOptIn;

    this.saveUserDetailsToStorage(notMyDevice, SponsorOptIn);
  }

  @action public saveUserDetailsToDatabase = async () => {
    const section = this.selectedSectionIsSpecial
      ? `Special: ${this.selectedSpecialSection}`
      : this.selectedSection;
    const row = this.selectedSectionIsSpecial
      ? `${this.selectedPlaceY}-${this.selectedPlaceHeight}`
      : `${this.selectedRow}`;
    const seat = this.selectedSectionIsSpecial
      ? `${this.selectedPlaceX}-${this.selectedPlaceWidth}`
      : `${this.selectedSeat}`;

    // save the user details to persistent storage
    const data = prepareSectionRowSeat(section, row, seat, this.selectedSuite,
      this.showSuites && !this.selectedSectionIsSpecial);
    try {
      await addUserRequest(
        {
          firstName: this.selectedFirstName,
          lastName: this.selectedLastName,
          email: this.selectedEmail,
          phoneNumber: this.selectedPhone,
          zipCode: this.selectedZipCode,
          birthDate: this.selectedBirthDate,
          country: this.selectedCountry,
          notMyDevice: this.selectedNotMyDevice,
          SponsorOptIn: this.selectedSponsorOptIn,
          game: this.selectedGame,
          section: data.section,
          row: data.row,
          seat: data.seat,
        },
      );
    } catch (err) {
      console.log("Cannot save user details");
    }
  }

  private saveEmailToStorage = async (notMyDevice: boolean) => {
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.EMAIL, notMyDevice ? "" : this.selectedEmail,
    );
  }

  private saveUserDetailsToStorage = async (
    notMyDevice: boolean,
    SponsorOptIn: boolean,
  ) => {
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.FIRST_NAME,
      notMyDevice ? "" : this.selectedFirstName,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.LAST_NAME,
      notMyDevice ? "" : this.selectedLastName,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.EMAIL,
      notMyDevice ? "" : this.selectedEmail,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PHONE,
      notMyDevice ? "" : this.selectedPhone,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.ZIP_CODE,
      notMyDevice ? "" : this.selectedZipCode,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.BIRTHDATE,
      notMyDevice ? "" : this.selectedBirthDate,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.COUNTRY,
      notMyDevice ? "" : this.selectedCountry,
    );
  };

  @action saveShowMomentCaption = (show: boolean) => {
    this.selectedShowMomentCaption = show;

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SHOW_MOMENT_CAPTION,
      show ? "Yes" : "No",
    );
  }

  // TODO: change to actual type
  @action saveMoment = (momentId: string, momentoSlide?: number) => {
    this.selectedMoment = momentId;

    this.saveDataToWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.MOMENT, momentId);

    if (momentoSlide !== undefined) {
      this.selectedMomento = momentoSlide || 0;
      this.saveDataToWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_MOMENTO, `${momentoSlide}`);
    }
  }

  @action deleteCurrentMoment = () => {
    this.selectedMoment = null;
    this.selectedMomento = null;
    this.deleteDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.MOMENT);
    this.deleteDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_MOMENTO);
  }

  @action public saveImageCropData = (
    cropData: ImageCropData,
    momentId?: string,
    momentoIndex?: number,
  ) => {
    if (!this.canManipulateCropData) return;

    const matchKey = this.generateCropDataKey(momentId, momentoIndex);

    // clear all previous data
    if (this.storageCropData) {
      for (const key of Object.keys(this.storageCropData)) {
        if (key.match(matchKey)) {
          delete this.storageCropData[key];
        }
      }
    }

    this.storageCropData = {
      ...this.storageCropData,
      [matchKey]: cropData,
    };

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.CROP_DATA,
      JSON.stringify(this.storageCropData),
    );
  }

  public getImageCropData = (momentId: string, momentoIndex: number): ImageCropData|null => {
    if (!this.canManipulateCropData || !this.storageCropData) return null;

    const momentoKey = this.generateCropDataKey(momentId, momentoIndex);
    if (this.storageCropData[momentoKey]) {
      return this.storageCropData[momentoKey];
    }

    const momentKey = this.generateCropDataKey(momentId);
    if (this.storageCropData[momentKey]) {
      return this.storageCropData[momentKey];
    }

    const globalKey = this.generateCropDataKey();
    return this.storageCropData[globalKey] || null;
  }

  public removeImageCropData = (
    momentId?: string,
    momentoIndex?: number,
  ): void => {
    const matchKey = this.generateCropDataKey(momentId, momentoIndex);

    if (!this.storageCropData || Object.keys(this.storageCropData).length === 0) {
      message.info("There's nothing to remove");
      return;
    }

    for (const key of Object.keys(this.storageCropData)) {
      if (key.match(matchKey)) {
        delete this.storageCropData[key];
      }
    }

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.CROP_DATA,
      JSON.stringify(this.storageCropData),
    );
  }

  public generateCropDataKey = (momentId?: string, momentoIndex? : number): string => {
    const specialSection = this.selectedSectionIsSpecial ? "special" : "";
    const selectedSection = this.selectedSectionIsSpecial ? this.selectedSpecialSection : this.selectedSection;
    const basicKey = `${this.selectedGame}_${selectedSection}_${specialSection}_${this.selectedRow}_${this.selectedSeat}_${this.selectedSuite}`;
    let resultKey = basicKey;
    if (momentId) {
      resultKey = `${resultKey}_${momentId}`;
    }
    if (momentoIndex || momentoIndex === 0) {
      resultKey = `${resultKey}_${momentoIndex}`;
    }
    return resultKey;
  }

  private canManipulateCropData = () => {
    let valid = true;
    if (!this.selectedGame) {
      showErrorNotification("No game selected");
      valid = false;
    }
    if (!this.selectedSeat && !this.selectedSectionIsSpecial) {
      showErrorNotification("No seat selected");
      valid = false;
    }
    return valid;
  }

  @action public saveSelectedMomentosData = (
    data: SelectedMomentosData,
    momentId?: string,
  ) => {
    if (!this.canManipulateCropData) return;

    const key = this.generateSelectedMomentosDataKey(momentId);

    this.storageSelectedMomentosData = {
      ...this.storageSelectedMomentosData,
      [key]: data,
    };

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_MOMENTOS,
      JSON.stringify(this.storageSelectedMomentosData),
    );
  }

  public getSelectedMomentosData = (momentId: string): SelectedMomentosData | null => {
    if (!this.canManipulateCropData || !this.storageSelectedMomentosData) return {};

    const momentKey = this.generateSelectedMomentosDataKey(momentId);
    if (this.storageSelectedMomentosData[momentKey]) {
      return this.storageSelectedMomentosData[momentKey] || {};
    }

    const globalKey = this.generateSelectedMomentosDataKey();
    return this.storageSelectedMomentosData[globalKey] || {};
  }

  public removeSelectedMomentosData = (
    momentId?: string,
  ): void => {
    const matchKey = this.generateSelectedMomentosDataKey(momentId);

    if (!this.storageSelectedMomentosData
      || Object.keys(this.storageSelectedMomentosData).length === 0) {
      message.info("There's nothing to remove");
      return;
    }

    for (const key of Object.keys(this.storageSelectedMomentosData)) {
      if (key.match(matchKey)) {
        delete this.storageSelectedMomentosData[key];
      }
    }

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_MOMENTOS,
      JSON.stringify(this.storageSelectedMomentosData),
    );
  }

  public generateSelectedMomentosDataKey = (momentId?: string): string => {
    const basicKey = this.selectedSectionIsSpecial
      ? `Selected_${this.selectedGame}_${this.selectedSpecialSection}_${this.selectedPlaceX}_${this.selectedPlaceY}`
      : `Selected_${this.selectedGame}_${this.selectedSection}_${this.selectedRow}_${this.selectedSeat}`;
    let resultKey = basicKey;
    if (momentId) {
      resultKey = `${resultKey}_${momentId}`;
    }
    return resultKey;
  }

  public getSelectedSpecialSection = () => {
    if (!this.selectedSectionIsSpecial || this.specialSectionsListLoading) {
      return null;
    }

    const specialSection = this.specialSections.find((sect) => sect.name === this.selectedSpecialSection);
    return specialSection;
  }

  private canManipulatePlaceData = () => {
    let valid = true;
    if (!this.selectedGame) {
      showErrorNotification("No game selected");
      valid = false;
    }

    if (!this.getSelectedSpecialSection()) {
      showErrorNotification("No special section selected");
      valid = false;
    }

    return valid;
  }

  @action public saveSelectedPlaceData = (
    cropData: ImageCropData,
  ) => {
    if (!this.canManipulatePlaceData) return;

    const matchKey = this.generatePlaceCropDataKey();

    // clear all previous data
    if (this.storageSelectedPlaceData) {
      for (const key of Object.keys(this.storageSelectedPlaceData)) {
        if (key.match(matchKey)) {
          delete this.storageSelectedPlaceData[key];
        }
      }
    }

    this.storageSelectedPlaceData = {
      ...this.storageSelectedPlaceData,
      [matchKey]: cropData,
    };

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_PLACE_DATA,
      JSON.stringify(this.storageSelectedPlaceData),
    );
  }

  public getSpecialSectionSlicingSize = (): number => {
    const specialSection = this.getSelectedSpecialSection();
    const width = specialSection?.slicingWidth || DEFAULT_SIZE;
    const height = specialSection?.slicingHeight || DEFAULT_SIZE;
    const size = width > height ? height : width;
    return size;
  }

  public getDefaultPlaceData = (): ImageCropData => {
    const size = this.getSpecialSectionSlicingSize();

    return {
      x: size > 50 ? 20 : 0,
      y: size > 50 ? 20 : 0,
      width: size > 50 ? size - 40 : size,
      height: size > 50 ? size - 40 : size,
      aspect: 1.0,
    };
  }

  public getSelectedPlaceData = (): ImageCropData | null => {
    if (!this.canManipulateCropData || !this.storageSelectedPlaceData) {
      return this.getDefaultPlaceData();
    }

    const globalKey = this.generatePlaceCropDataKey();
    return this.storageSelectedPlaceData[globalKey] || this.getDefaultPlaceData();
  }

  public removeSelectedPlaceData = (): void => {
    const matchKey = this.generatePlaceCropDataKey();

    if (!this.storageSelectedPlaceData || Object.keys(this.storageSelectedPlaceData).length === 0) {
      message.info("There's nothing to remove");
      return;
    }

    for (const key of Object.keys(this.storageSelectedPlaceData)) {
      if (key.match(matchKey)) {
        delete this.storageSelectedPlaceData[key];
      }
    }

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_PLACE_DATA,
      JSON.stringify(this.storageSelectedPlaceData),
    );
  }

  public generatePlaceCropDataKey = (): string => {
    const basicKey = `Place_${this.selectedGame}_${this.selectedSpecialSection}`;
    return basicKey;
  }

  @action public saveShownTips = (tipsCategory: string, show: boolean) => {
    this.shownTipsData = {
      ...this.shownTipsData,
      [tipsCategory]: show,
    };

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SHOWN_TIPS, JSON.stringify(this.shownTipsData),
    );
  }

  @action public saveLastPlaces = (last: LastPlace[]) => {
    this.lastPlaces = [
      ...last,
    ];

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.LAST_PLACES, JSON.stringify(this.lastPlaces),
    );
  }

  private fetchDataFromWorkerStorage = async (entity: SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM) => {
    try {
      let { data } = await axios.get<string>(entity);
      // sometimes axios return main html instead of the new key. Clear such values
      // eslint-disable-next-line quotes
      if (data && data.startsWith('<!doctype html><html lang="en"><head><meta charset="utf-8"/><link rel="icon" href="./favicon.ico"/>')) {
        data = "";
      }

      if (data) {
        runInAction(() => {
          switch (entity) {
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.GAME:
              console.log(`> Found stored game ${data}`);
              this.selectedGame = data;
              this.fetchGameData();
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION:
              console.log(`> Found stored section ${data}`);
              this.selectedSection = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.ROW:
              console.log(`> Found stored row ${data}`);
              this.selectedRow = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SEAT:
              console.log(`> Found stored seat ${data}`);
              this.selectedSeat = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SUITE:
              console.log(`> Found stored suite ${data}`);
              this.selectedSuite = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.MOMENT:
              console.log(`> Found stored moment ${data}`);
              this.selectedMoment = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_MOMENTO:
              console.log(`> Found stored momento ${data}`);
              this.selectedMomento = Number(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.CROP_DATA:
              console.log(`> Found stored crop data ${data}`);
              this.storageCropData = JSON.parse(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_PLACE_DATA:
              console.log(`> Found stored place data ${data}`);
              this.storageSelectedPlaceData = JSON.parse(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SELECTED_MOMENTOS:
              console.log(`> Found stored selection data ${data}`);
              this.storageSelectedMomentosData = JSON.parse(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SHOWN_TIPS:
              console.log(`> Found shown tips data ${data}`);
              this.shownTipsData = JSON.parse(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.LAST_PLACES:
              console.log(`> Found last places data ${data}`);
              this.lastPlaces = data ? JSON.parse(data) : [];
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.VERIFY_LAST_NAME:
              console.log(`> Found stored verify last name data ${data}`);
              this.selectedVerifyLastName = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.FIRST_NAME:
              console.log(`> Found stored firstName data ${data}`);
              this.selectedFirstName = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.LAST_NAME:
              console.log(`> Found stored lastName data ${data}`);
              this.selectedLastName = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.EMAIL:
              console.log(`> Found stored email data ${data}`);
              this.selectedEmail = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PHONE:
              console.log(`> Found stored phone data ${data}`);
              this.selectedPhone = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.ZIP_CODE:
              console.log(`> Found stored zip code data ${data}`);
              this.selectedZipCode = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.BIRTHDATE:
              console.log(`> Found stored birth date data ${data}`);
              this.selectedBirthDate = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.COUNTRY:
              console.log(`> Found stored country data ${data}`);
              this.selectedCountry = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SHOW_MOMENT_CAPTION:
              console.log(`> Found stored show moment caption ${data}`);
              this.selectedShowMomentCaption = data !== "No";
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION_IS_SPECIAL:
              console.log(`> Found stored section is special flag ${data}`);
              this.selectedSectionIsSpecial = data === "Yes";
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION_TYPE:
              console.log(`> Found stored section type ${data}`);
              this.selectedSectionType = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_X:
              console.log(`> Found stored Place X ${data}`);
              this.selectedPlaceX = Number(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_Y:
              console.log(`> Found stored Place Y ${data}`);
              this.selectedPlaceY = Number(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_WIDTH:
              console.log(`> Found stored Place Width ${data}`);
              this.selectedPlaceWidth = Number(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.PLACE_HEIGHT:
              console.log(`> Found stored Place Height ${data}`);
              this.selectedPlaceHeight = Number(data);
              break;
            default:
              break;
          }
        });
      } else {
        console.log(`> No data was found in the storage for entity ${entity}`);
      }
    } catch (err) {
      console.log("Something went wrong while searching for the data in the worker's storage");
    }
  }

  private fetchGameData = async (gameId?: string) => {
    try {
      this.selectedGameData = await getGameDataByIdRequest(gameId || this.selectedGame);
      this.hidePurchaseActions = this.selectedGameData?.team1?.hidePurchase
        || this.selectedGameData?.team2?.hidePurchase;
      this.hideDownloadActions = this.selectedGameData?.team1?.hideDownload
        || this.selectedGameData?.team2?.hideDownload;
      this.showOrderPrintedPhoto = this.selectedGameData?.team1?.showOrderPrintedPhoto;
      await this.fetchSpecialSectionsData();
      await this.fetchTeamsData();
    } catch (err) {
      showErrorNotification(`[fetchGameData] ${err.message}`);
    }
  }

  private fetchTeamsData = async () => {
    this.clearCachedImages();

    await this.loadTeamAdsMetadata();
    await this.loadTeamLogos();
    await this.loadTeamAdsImages();
  }

  private fetchSpecialSectionsData = async () => {
    this.specialSectionsListLoading = true;
    this.specialSections = [];
    this.allSuites = [];
    this.showSuites = false;
    this.availableSections = [];
    if (this.selectedGameData?.stadiumId) {
      const allSections = await getAllSections(this.selectedGameData.stadiumId);
      // const specialSections = await getSpecialSections(this.selectedGameData.stadiumId);
      const sorted = allSections.sort((item1, item2) => {
        const item1SectionType = (item1?.sectionType || "").toUpperCase();
        const item2SectionType = (item2?.sectionType || "").toUpperCase();
        const item1Name = (item1?.name || "").toUpperCase();
        const item2Name = (item2?.name || "").toUpperCase();
        if (item1SectionType < item2SectionType) {
          return -1;
        }

        if (item1SectionType === item2SectionType && item1Name < item2Name) {
          return -1;
        }

        if (item1SectionType === item2SectionType && item1Name === item2Name) {
          return 0;
        }

        return 1;
      });

      this.specialSections = sorted.filter((section) => section.isSpecialSection);
      this.allSuites = sorted.filter((section) => getSuiteName(section.name));
      this.showSuites = this.allSuites.length > 0;
      this.availableSections = sorted.map((section) => ({
        ...section,
        rows: section.rows || [],
        seats: section.seats || {},
      }));
    }

    this.specialSectionsListLoading = false;
  }

  private saveDataToWorkerStorage = async (
    entity: SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM,
    data: string,
  ) => {
    try {
      await axios.post(entity, { data });
    } catch (err) {
      console.log("^^^ The error above is expected xhr error");
    }
  }

  private deleteDataFromWorkerStorage = async (entity: SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM) => {
    try {
      await axios.delete(entity);
    } catch (err) {
      console.log("^^^ The error above is expected xhr error");
    }
  }

  // load all team logos
  private loadTeamLogos = async () => {
    if (!this.selectedGameData?.team1?.logo || !this.selectedGameData?.team2?.logo) {
      this.team1logoImage = null;
      this.team1logoDataUrl = null;
      this.team1logoSrc = null;
      this.team1PaywallImage = null;
      this.team1PaywallDataUrl = null;
      this.team1PaywallSrc = null;
      this.team1TicketAdImage = null;
      this.team1TicketAdDataUrl = null;
      this.team1TicketAdSrc = null;
      this.team1PopupImage = null;
      this.team1PopupDataUrl = null;
      this.team1PopupSrc = null;
      this.team2logoImage = null;
      this.team2logoDataUrl = null;
      this.team2logoSrc = null;
      this.mainLogoImage = null;

      return;
    }

    this.team1Color = this.selectedGameData.team1.primaryColor || COLORS.GREEN_DARK;
    this.team2Color = this.selectedGameData.team2.primaryColor || COLORS.GREY_DARK;

    const team1Src = this.selectedGameData.team1.logo;
    const team2Src = this.selectedGameData.team2.logo;

    // check if image was already loaded
    if (!(team1Src === this.team1logoSrc && this.team1logoDataUrl && this.team1logoImage?.width)) {
      this.team1logoImage = null;
      // load from cached dataUrl if possible
      const src = team1Src === this.team1logoSrc && this.team1logoDataUrl
        ? this.team1logoDataUrl : team1Src;
      const { image: team1Image, dataURL: team1DataURL } = await this.loadTeamLogo(src);
      this.team1logoImage = team1Image;
      this.team1logoDataUrl = team1DataURL;
      this.team1logoSrc = team1Src;
    }

    const team1PaywallSrc = this.selectedGameData.team1.paywallImage;
    if (!team1PaywallSrc) {
      this.team1PaywallImage = null;
      this.team1PaywallDataUrl = null;
      this.team1PaywallSrc = null;
    }

    // check if image was already loaded
    if (team1PaywallSrc && !(team1PaywallSrc === this.team1PaywallSrc && this.team1PaywallDataUrl && this.team1PaywallImage?.width)) {
      this.team1PaywallImage = null;
      // load from cached dataUrl if possible
      const src = team1PaywallSrc === this.team1PaywallSrc && this.team1PaywallDataUrl
        ? this.team1PaywallDataUrl : team1PaywallSrc;
      const { image: team1PaywallImage, dataURL: team1PaywallDataURL } = await this.loadTeamLogo(src);
      this.team1PaywallImage = team1PaywallImage;
      this.team1PaywallDataUrl = team1PaywallDataURL;
      this.team1PaywallSrc = team1PaywallSrc;
    }

    const team1TicketAdSrc = this.selectedGameData.team1.ticketAdImage;
    if (!team1TicketAdSrc) {
      this.team1TicketAdImage = null;
      this.team1TicketAdDataUrl = null;
      this.team1TicketAdSrc = null;
    }

    // check if image was already loaded
    if (team1TicketAdSrc && !(team1TicketAdSrc === this.team1TicketAdSrc && this.team1TicketAdDataUrl && this.team1TicketAdImage?.width)) {
      this.team1TicketAdImage = null;
      // load from cached dataUrl if possible
      const src = team1TicketAdSrc === this.team1TicketAdSrc && this.team1TicketAdDataUrl
        ? this.team1TicketAdDataUrl : team1TicketAdSrc;
      const { image: team1TicketAdImage, dataURL: team1TicketAdDataURL } = await this.loadTeamLogo(src);
      this.team1TicketAdImage = team1TicketAdImage;
      this.team1TicketAdDataUrl = team1TicketAdDataURL;
      this.team1TicketAdSrc = team1TicketAdSrc;
    }

    const team1PopupSrc = this.selectedGameData.team1.popupImage;
    if (!team1PopupSrc) {
      this.team1PopupImage = null;
      this.team1PopupDataUrl = null;
      this.team1PopupSrc = null;
    }

    // check if image was already loaded
    if (team1PopupSrc && !(team1PopupSrc === this.team1PopupSrc && this.team1PopupDataUrl && this.team1PopupImage?.width)) {
      this.team1PopupImage = null;
      // load from cached dataUrl if possible
      const src = team1PopupSrc === this.team1PopupSrc && this.team1PopupDataUrl
        ? this.team1PopupDataUrl : team1PopupSrc;
      const { image: team1PopupImage, dataURL: team1PopupDataURL } = await this.loadTeamLogo(src);
      this.team1PopupImage = team1PopupImage;
      this.team1PopupDataUrl = team1PopupDataURL;
      this.team1PopupSrc = team1PopupSrc;
    }

    // check if image was already loaded
    if (!(team2Src === this.team2logoSrc && this.team2logoDataUrl && this.team2logoImage?.width)) {
      this.team2logoImage = null;
      // load from cached dataUrl if possible
      const src = team2Src === this.team2logoSrc && this.team2logoDataUrl
        ? this.team2logoDataUrl : team2Src;
      const { image: team2Image, dataURL: team2DataURL } = await this.loadTeamLogo(src);
      this.team2logoImage = team2Image;
      this.team2logoDataUrl = team2DataURL;
      this.team2logoSrc = team2Src;
    }

    // check if image was already loaded
    if (!this.mainLogoImage?.width) {
      this.mainLogoImage = null;
      // load from cached dataUrl if possible
      const src = LogoIcon;
      const { image: mainImage, dataURL: mainDataURL } = await this.loadTeamLogo(src, false);
      this.mainLogoImage = mainImage;
    }
  }

  private loadTeamLogo = (src, getDataURL = true): Promise<ImageLoadResult> => {
    return loadImage(src, getDataURL);
  }

  private loadAdvertsment = async (src): Promise<Blob> => {
    const res = await fetch(src);
    const blob = await res.blob();
    return blob;

    // return URL.createObjectURL(blob);
  }

  // load all team advertisments metadata
  public loadTeamAdsMetadata = async () => {
    if (!this.selectedGameData?.team1?._id) {
      this.teamAds = null;
      return;
    }

    const teamAds = await getTeamAdsByTeamId(this.selectedGameData?.team1?._id) || {};
    this.teamAds = teamAds;
  }

  // load all team advertisments
  public loadTeamAdsImages = async () => {
    if (!this.teamAds) {
      this.team1SponsorLogoImage = null;
      this.team1SponsorLogoDataUrl = null;
      this.team1SponsorLogoSrc = null;
      return;
    }

    const teamAds = this.teamAds || {};
    if (teamAds.titleSponsorLogoKey && teamAds.titleSponsorLogoImage) {
      const sponsorLogoSrc = teamAds.titleSponsorLogoImage;
      // check if image was already loaded
      if (!(sponsorLogoSrc === this.team1SponsorLogoSrc
          && this.team1SponsorLogoDataUrl
          && this.team1SponsorLogoImage?.width)) {
        this.team1SponsorLogoImage = null;
        // load from cached dataUrl if possible
        const src = sponsorLogoSrc === this.team1SponsorLogoSrc && this.team1SponsorLogoDataUrl
          ? this.team1SponsorLogoDataUrl : sponsorLogoSrc;
        const { image, dataURL } = await this.loadTeamLogo(src);
        this.team1SponsorLogoImage = image;
        this.team1SponsorLogoDataUrl = dataURL;
        this.team1SponsorLogoSrc = sponsorLogoSrc;
      }
    } else {
      this.team1SponsorLogoImage = null;
      this.team1SponsorLogoDataUrl = null;
      this.team1SponsorLogoSrc = null;
    }

    if (teamAds.adAfterContactInfoImage) {
      const adSrc = teamAds.adAfterContactInfoImage;
      // check if ad was already loaded
      if (!(adSrc === this.adAfterContactInfoSrc
          && this.adAfterContactInfoBlob)) {
        const blob = await this.loadAdvertsment(adSrc);
        this.adAfterContactInfoBlob = blob;
        this.adAfterContactInfoSrc = adSrc;
      }
    } else {
      this.adAfterContactInfoBlob = null;
      this.adAfterContactInfoSrc = null;
    }
  }

  // clear cached images
  private clearCachedImages = () => {
    this.teamAds = null;
    this.team1logoImage = null;
    this.team1logoDataUrl = null;
    this.team1logoSrc = null;
    this.team1PaywallImage = null;
    this.team1PaywallDataUrl = null;
    this.team1PaywallSrc = null;
    this.team1TicketAdImage = null;
    this.team1TicketAdDataUrl = null;
    this.team1TicketAdSrc = null;
    this.team1PopupImage = null;
    this.team1PopupDataUrl = null;
    this.team1PopupSrc = null;
    this.team2logoImage = null;
    this.team2logoDataUrl = null;
    this.team2logoSrc = null;
    this.mainLogoImage = null;
    this.team1SponsorLogoImage = null;
    this.team1SponsorLogoDataUrl = null;
    this.team1SponsorLogoSrc = null;

    this.adAfterContactInfoBlob = null;
    this.adAfterContactInfoSrc = null;
  }

  @action public setShowAdAfterContactInfo = (show: boolean) => {
    this.showAdAfterContactInfo = show;
  }

  public setMomentCameraData = (data: MomentResponseWithSize) => {
    this.defaultZoomWidth = data.defaultZoomWidth;
    this.defaultZoomHeight = data.defaultZoomHeight;
    this.centerCoords = data.relativeCoords;
    this.offsetY = data.offsetTop;
  }

  @action public setResumeSessionDialogWasShown = (value: boolean) => {
    this.resumeSessionDialogWasShown = value;
  }

  public getNextPage = (id: string) => {
    switch (id) {
      case Routes.WELCOME:
        return this.showUserDetailsFirst ? Routes.DETAILS : Routes.GAMES;
      case Routes.GAMES:
        return Routes.SEAT;
      case Routes.SEAT:
        if (this.selectedSectionIsSpecial) {
          return Routes.PLACE;
        }
        if (this.selectedGameData?.checkLastName) {
          return Routes.VERIFY_SEAT;
        }
        return this.showUserDetailsFirst ? Routes.MY_MOMENTS : Routes.DETAILS;
      case Routes.PLACE:
        if (this.selectedGameData?.checkLastName) {
          return Routes.VERIFY_SEAT;
        }
        return this.showUserDetailsFirst ? Routes.MY_MOMENTS : Routes.DETAILS;
      case Routes.DETAILS:
        if (this.showUserDetailsFirst) {
          return Routes.GAMES;
        }
        return Routes.MY_MOMENTS;
      case Routes.VERIFY_SEAT:
        return Routes.MY_MOMENTS;
      default:
    }

    return id;
  }

  // performs necessary saving and moves router to the next page
  @action public moveToTheNextPage = (id: string) => {
    const previousId = this.getNextPage(id);
    AppServiceInstance.history.push(previousId);
  }

  private getPreviousPage = (id: string) => {
    switch (id) {
      case Routes.GAMES:
        return this.showUserDetailsFirst ? Routes.DETAILS : Routes.WELCOME;
      case Routes.SEAT:
        return Routes.GAMES;
      case Routes.PLACE:
        return Routes.SEAT;
      case Routes.DETAILS:
        if (this.showUserDetailsFirst) {
          return Routes.WELCOME;
        }
        return this.selectedSectionIsSpecial ? Routes.PLACE : Routes.SEAT;
      case Routes.VERIFY_SEAT:
        return this.selectedSectionIsSpecial ? Routes.PLACE : Routes.SEAT;
      case Routes.MY_MOMENTS:
      case Routes.MOMENT:
        if (this.selectedGameData?.checkLastName) {
          return Routes.VERIFY_SEAT;
        }
        if (!this.showUserDetailsFirst) {
          return Routes.DETAILS;
        }
        return this.selectedSectionIsSpecial ? Routes.PLACE : Routes.SEAT;
        break;
      default:
    }

    return id;
  }

  // moves router to the previous page
  @action public moveToThePreviousPage = (id: string) => {
    const previousId = this.getPreviousPage(id);
    AppServiceInstance.history.push(previousId);
  }

  // indicates that popup image was shown on this session
  @action public teamPopupWasShown = () => {
    this.popupImageWasShown = true;
    this.showPopupImage = false;
  }
}

// add fetch method

export const StorageServiceInstance = new StorageService();

export const StorageServiceContext = createContext(StorageServiceInstance);
