import { message as AntdMessage } from "antd";
import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { createContext } from "react";
import * as _ from "lodash";
import {
  addPurchaseLink,
  checkGameMomentRequest,
  checkLastNameRequest,
  fetchGameMomentsRequest,
  getCroppedVideo,
  getPurchasedImage,
  reservePrintOrderNumber,
} from "../axios/routes/moment";
import {
  CanvasPositionData,
  EditorSaveType,
  SelectedMomentosData,
} from "../interfaces/moment";
import {
  Coords,
  GetGameMomentsRequests,
  IntervalGamePopulated,
  MomentResponse,
  UniqueUser,
} from "../shared/interfaces";
import { getGameStartDate } from "../utils/date";
import { getViewAreaPosition } from "../utils/image";
import { showErrorNotification } from "../utils/notification";
import { DEFAULT_SIZE, MAX_SPECIAL_SECTION_WIDTH, StorageServiceContext, StorageServiceInstance } from "./storage";
import { GetCroppedVideoRequest } from "../shared/interfaces/moment";
import { MomentImageCacheService } from "./image-cache-service/moment-image-cache-service";
import { addPendingPayment, payForTheGame, updateLastSessionDate } from "../axios/routes/user";
import { AppServiceInstance } from "./app";
import { Routes } from "../utils/routes";
import { prepareSectionRowSeat } from "../utils/suite";
import {
  ASPECT_RATIO_LIST, ImageCoordinates, ImageCropData, ImageSize,
  calculateImageSize, calculateImageStartPosition, DownloadData,
} from "../utils/sharedFunctions";

// additional service information for the loaded moment
export interface MomentData {
  // referenced moment id
  _id: string;
  // currently selected moment slide
  selectedMomentoSlide: number;
  // moment GIF is not prepared
  gifLoading?: boolean;
  // moment GIF Image
  gifImageToPlay?: any;
}

export const MOMENTS_PAGE_SIZE = 10;

class MomentService {
  cacheService: MomentImageCacheService = new MomentImageCacheService();

  @observable momentList: Array<MomentResponse> = [];

  // ability to prepare not all momentos at once
  @observable momentsPreparedUntil = 0;

  // to know unpaid moments count
  @observable totalMomentsCount = 0;

  @observable totalMomentosCount = 0;

  // to know if moments are paid
  @observable isPaid = false;

  // additional information about moments
  @observable momentsData: Array<MomentData> = [];

  @observable selectedMoment: MomentResponse = null;

  @observable loading = false;

  @observable lastMomentRequest: GetGameMomentsRequests = null;

  private defaultZoomWidth: number = null;

  private defaultZoomHeight: number = null;

  private centerCoords: Coords = null;

  private offsetY: number = null;

  private timeOut = 1;

  private returnedMomentWidth = 0;

  private returnedMomentHeight = 0;

  @observable editorAspect: ASPECT_RATIO_LIST = ASPECT_RATIO_LIST["3_4"];

  @observable selectedMomentoSlide: number = null;

  // for old momento page
  @observable gifPlaying = false;

  // for old momento page
  @observable gifLoading = false;

  // for old momento page
  @observable gifImageToPlay: string = null;

  // moment to scroll to after navigation
  @observable momentIdToScroll: string = null;

  // show moments from this one
  @observable startingMomentIndex = 0;

  private momentImagesRefs: Array<any> = [];

  // share dialog
  @observable shareDialogIsVisible = false;

  @observable shareFiles: unknown[] = [];

  @observable shareType = "";

  @observable DEBUG_APP_TYPE = ""; // "" or "pregenGIF"

  @observable specialSectionImageInfo: MomentResponse[] = [];

  @observable specialSectionImageLoading = false;

  @observable specialSecitonImageCompressRate = 1.0;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => StorageServiceInstance.selectedMoment,
      (id) => {
        this.selectedMoment = this.momentList.find((m) => m._id === id);
      },
    );

    reaction(
      () => StorageServiceInstance.selectedMomento,
      (id) => {
        this.selectedMomentoSlide = id;
      },
    );
  }

  public getSpecialSectionSlicedArea = () => {
    const specialSection = StorageServiceInstance.getSelectedSpecialSection();
    if (!specialSection) {
      return {
        x: 0,
        y: 0,
        width: DEFAULT_SIZE,
        height: DEFAULT_SIZE,
        offsetX: 0,
        offsetY: 0,
      };
    }

    const sectionX = specialSection?.sectionX || 0;
    const sectionY = specialSection?.sectionY || 0;
    const sectionWidth = specialSection?.sectionWidth || DEFAULT_SIZE;
    const sectionHeight = specialSection?.sectionHeight || DEFAULT_SIZE;

    const slicingWidth = specialSection?.slicingWidth || DEFAULT_SIZE;
    const slicingHeight = specialSection?.slicingHeight || DEFAULT_SIZE;
    const centerX = sectionX
      + StorageServiceInstance.selectedPlaceX + StorageServiceInstance.selectedPlaceWidth / 2.0;
    const centerY = sectionY
      + StorageServiceInstance.selectedPlaceY + StorageServiceInstance.selectedPlaceHeight / 2.0;

    const x = centerX - slicingWidth / 2.0;
    const y = centerY - slicingHeight / 2.0;
    const offsetX = x < 0 ? -x : 0;
    const offsetY = y < 0 ? -y : 0;

    return {
      x,
      y,
      width: slicingWidth,
      height: slicingHeight,
      offsetX,
      offsetY,
    };
  }

  public getRectInSlicedArea = () => {
    const specialSection = StorageServiceInstance.getSelectedSpecialSection();
    if (!specialSection) {
      return {
        x: 0,
        y: 0,
        width: DEFAULT_SIZE,
        height: DEFAULT_SIZE,
        offsetX: 0,
        offsetY: 0,
      };
    }

    const sectionX = specialSection?.sectionX || 0;
    const sectionY = specialSection?.sectionY || 0;
    const sectionWidth = specialSection?.sectionWidth || DEFAULT_SIZE;
    const sectionHeight = specialSection?.sectionHeight || DEFAULT_SIZE;

    const slicingWidth = specialSection?.slicingWidth || DEFAULT_SIZE;
    const slicingHeight = specialSection?.slicingHeight || DEFAULT_SIZE;

    let areaX = 0;
    let areaWidth = slicingWidth;
    if (StorageServiceInstance.selectedPlaceWidth < slicingWidth) {
      areaWidth = StorageServiceInstance.selectedPlaceWidth;
      areaX = (slicingWidth - StorageServiceInstance.selectedPlaceWidth) / 2.0;
    }

    let areaY = 0;
    let areaHeight = slicingHeight;
    if (StorageServiceInstance.selectedPlaceWidth < slicingHeight) {
      areaHeight = StorageServiceInstance.selectedPlaceHeight;
      areaY = (slicingHeight - StorageServiceInstance.selectedPlaceHeight) / 2.0;
    }

    return {
      x: areaX,
      y: areaY,
      width: areaWidth,
      height: areaHeight,
    };
  }

  private generateMomentRequest = (
    useSlicingSizesForSpecialSection: boolean,
    compressSpecialSections: boolean,
    section?: string,
    row?: string,
    seat?: string,
    fromMoment?: number,
    toMoment?: number,
  ): GetGameMomentsRequests => {
    if (StorageServiceInstance.selectedSectionIsSpecial) {
      const specialSection = StorageServiceInstance.getSelectedSpecialSection();

      let seatX = specialSection?.sectionX || 0;
      let seatY = specialSection?.sectionY || 0;
      let seatWidth = specialSection?.sectionWidth || DEFAULT_SIZE;
      let seatHeight = specialSection?.sectionHeight || DEFAULT_SIZE;

      // area with slicing sizes accross the center of place area
      if (useSlicingSizesForSpecialSection) {
        const slicedArea = this.getSpecialSectionSlicedArea();
        seatX = slicedArea.x;
        seatY = slicedArea.y;
        seatWidth = slicedArea.width;
        seatHeight = slicedArea.height;
      }

      const maxSize = seatWidth > seatHeight ? seatWidth : seatHeight;
      const resizeWidth = compressSpecialSections && maxSize > MAX_SPECIAL_SECTION_WIDTH ? MAX_SPECIAL_SECTION_WIDTH : undefined;
      const compressRate = !!resizeWidth && maxSize > 0 ? resizeWidth / maxSize : undefined;

      return {
        mappedSeat: {
          sectionName: section || StorageServiceInstance.selectedSpecialSection,
          row: "",
          seat: "",
          isSpecialSection: true,
          seatX,
          seatY,
          width: seatWidth,
          height: seatHeight,
        },
        from: StorageServiceInstance.selectedGameData.startDate,
        to: StorageServiceInstance.selectedGameData.finishDate || null,
        stadium: StorageServiceInstance.selectedGameData.stadiumId,
        resizeWidth,
        compressRate,
        gameId: StorageServiceInstance.selectedGameData._id,
        email: StorageServiceInstance.selectedEmail,
        fromMoment,
        toMoment,
      };
    }

    const data = prepareSectionRowSeat(
      section || StorageServiceInstance.selectedSection,
      row || StorageServiceInstance.selectedRow,
      seat || StorageServiceInstance.selectedSeat,
      StorageServiceInstance.selectedSuite,
      true, // StorageServiceInstance.showSuites,
    );

    return {
      mappedSeat: {
        sectionName: data.section,
        row: data.row,
        seat: data.seat,
      },
      from: StorageServiceInstance.selectedGameData.startDate,
      to: StorageServiceInstance.selectedGameData.finishDate || null,
      stadium: StorageServiceInstance.selectedGameData.stadiumId,
      gameId: StorageServiceInstance.selectedGameData._id,
      email: StorageServiceInstance.selectedEmail,
      fromMoment,
      toMoment,
    };
  };

  public checkMoments = async (
    section: string,
    row: string,
    seat: string,
    suite?: string,
    show?: boolean,
  ): Promise<boolean> => {
    const data = prepareSectionRowSeat(section, row, seat, suite, show);
    const request = this.generateMomentRequest(false, false, data.section, data.row, data.seat);

    const { message, success } = await checkGameMomentRequest(request);

    if (message) {
      AntdMessage.warn(message);
    }

    return success;
  };

  public checkLastName = async (
    gameId: string,
    lastName: string,
  ): Promise<boolean> => {
    const { message, success } = await checkLastNameRequest({
      gameId,
      lastName,
    });

    if (message) {
      AntdMessage.warn(message);
    }

    return success;
  };

  public fetchMomentListWithTimeout = async () => {
    // moments are already loaded or loading is active
    if (this.momentList.length || this.loading) {
      return;
    }

    // try again with progressing timeout to load selected game
    if (!StorageServiceInstance.selectedGameData) {
      if (this.timeOut > 10) {
        this.timeOut = 1;
        // stop attempts
        return;
      }

      const currentTimeOut = this.timeOut * 1000;
      if (this.timeOut === 10) {
        this.timeOut = 15;
      }

      if (this.timeOut === 5) {
        this.timeOut = 10;
      }

      if (this.timeOut === 1) {
        this.timeOut = 5;
      }

      setTimeout(() => {
        this.fetchMomentListWithTimeout();
      }, currentTimeOut);

      return;
    }

    this.fetchMomentList();
  }

  // return moments of the game first
  private compareMoments = (m1, m2) => {
    if (m2.isMOTG && !m1.isMOTG) {
      return 1;
    }

    if (!m2.isMOTG && m1.isMOTG) {
      return -1;
    }

    return m2.momentStart - m1.momentStart;
  }

  public fetchMomentList = async (prepareUntil = 0) => {
    if (this.loading || !StorageServiceInstance.selectedGameData) return;
    this.timeOut = 1;
    const prepareMomentsUntil = prepareUntil || MOMENTS_PAGE_SIZE;

    // VCB MMNT-161
    // if (!this.dependenciesLoaded()) {
    //   this.schedule(this.fetchMomentList);
    //   return;
    // }

    this.momentList = [];
    this.totalMomentsCount = 0;
    this.totalMomentosCount = 0;
    this.isPaid = false;
    this.momentsData = [];
    this.loading = true;
    this.returnedMomentWidth = 0;
    this.returnedMomentHeight = 0;
    try {
      const request: GetGameMomentsRequests = this.generateMomentRequest(true, false, undefined, undefined, undefined, 0, prepareMomentsUntil);

      const { data, error } = await fetchGameMomentsRequest(request);

      this.momentIdToScroll = null;
      this.startingMomentIndex = 0;
      if (error) {
        this.momentList = [];
        this.momentsData = [];
        this.totalMomentsCount = 0;
        this.totalMomentosCount = 0;
        this.isPaid = true;
        this.momentsPreparedUntil = 0;
        AntdMessage.warn(error);
      } else {
        const sortedMoments = data.moments.sort(this.compareMoments);
        runInAction(() => {
          this.lastMomentRequest = request;
          this.momentsData = sortedMoments.map((m) => {
            return {
              _id: m._id,
              selectedMomentoSlide: 0,
              gifLoading: true,
            };
          });

          this.momentList = sortedMoments;
          this.totalMomentsCount = data.momentsTotalCount || this.momentList.length;
          this.totalMomentosCount = data.momentosTotalCount
            || this.momentList.reduce((acc, oneMoment) => {
              const newAcc = acc + oneMoment.momentoLinks.length || 0;
              return newAcc;
            }, 0);
          this.isPaid = data.isPaid || false;
          this.returnedMomentWidth = data.width;
          this.returnedMomentHeight = data.height;
          this.momentsPreparedUntil = prepareMomentsUntil;

          StorageServiceInstance.setMomentCameraData(data);
          const isPregenGIFApp = this.isPregenerateGIFApp();
          if (isPregenGIFApp) {
            this.cacheService.generateGIFs = isPregenGIFApp;
            this.cacheService.preCacheMomentImages(this.momentList, 0, prepareMomentsUntil);
          } else {
            // precache first moment
            this.cacheService.preCacheMomentImages(this.momentList, 0, 1);
          }

          // MMNT-163 @ram Adjust this so that the rendered images
          // from the new cache service get displayed here when they are done...
          if (isPregenGIFApp) {
            this.cacheService.gifImages$.subscribe((caches) => {
              caches.forEach((cache) => {
                console.log(`*** gifImageDataUrl: ${cache.gifImage?.src}`);
                const hasGif = !!cache.gifImage?.src;
                this.momentsData = this.momentsData.map((mData) => {
                  return mData._id === cache.momentId
                    ? {
                      ...mData,
                      gifLoading: !hasGif,
                      gifImageToPlay: cache.gifImage?.src || null,
                    }
                    : mData;
                });
                // this.setGIFPlaying(true);
                // this.setGIFLoading(false);
              });

              // this.cacheService.momentImageCaches.forEach((cache) => {
              //   if (cache.gifImage) {
              //     console.log(`*** gifImageDataUrl: ${cache.gifImage.src}`);
              //     this.momentsData = this.momentsData.map((mData) => {
              //       return mData._id === cache.momentId
              //         ? {
              //           ...mData,
              //           gifLoading: false,
              //           gifImageToPlay: cache.gifImage.src,
              //         }
              //         : mData;
              //     });
              //   }
              // });
            });
          }

          this.defaultZoomWidth = data.defaultZoomWidth;
          this.defaultZoomHeight = data.defaultZoomHeight;
          this.centerCoords = data.relativeCoords;
          this.offsetY = data.offsetTop;

          if (StorageServiceInstance.selectedMoment) {
            this.selectedMoment = this.momentList.find(
              (m) => m._id === StorageServiceInstance.selectedMoment,
            );
            // VCB MMNT-161
            // Let's remove this because I don't think it's necessary...
            // this.setAspectRatioWithStore();
          }
        });
      }
    } catch (err) {
      showErrorNotification(`[fetchMomentList] ${err.message}`);
    } finally {
      this.loading = false;
    }
  };

  public precacheMoments = (from = 0, count = 1) => {
    if (!this.momentList) {
      return;
    }
    if (from >= this.momentList.length) {
      return;
    }

    const to = Math.min(from + count, this.momentList.length);
    this.cacheService.preCacheMomentImages(this.momentList, from, to);
  }

  public fetchMomentListPart = async (from = 0, to = 0) => {
    if (this.loading || !StorageServiceInstance.selectedGameData) return;
    const loadUntil = to || from + MOMENTS_PAGE_SIZE;
    if (loadUntil <= this.momentsPreparedUntil) {
      return;
    }

    this.loading = true;
    try {
      const request: GetGameMomentsRequests = this.generateMomentRequest(true, false, undefined, undefined, undefined, from, loadUntil);
      const { data, error } = await fetchGameMomentsRequest(request);

      if (error) {
        AntdMessage.warn(error);
      } else {
        runInAction(() => {
          this.momentsPreparedUntil = loadUntil;

          const isPregenGIFApp = this.isPregenerateGIFApp();
          if (isPregenGIFApp) {
            this.cacheService.preCacheMomentImages(this.momentList, from, loadUntil);
          }

          // MMNT-163 @ram Adjust this so that the rendered images
          // from the new cache service get displayed here when they are done...
          if (isPregenGIFApp) {
            this.cacheService.gifImages$.subscribe((caches) => {
              caches.forEach((cache) => {
                console.log(`*** gifImageDataUrl: ${cache.gifImage?.src}`);
                const hasGif = !!cache.gifImage?.src;
                this.momentsData = this.momentsData.map((mData) => {
                  return mData._id === cache.momentId
                    ? {
                      ...mData,
                      gifLoading: !hasGif,
                      gifImageToPlay: cache.gifImage?.src || null,
                    }
                    : mData;
                });
              });
            });
          }
        });
      }
    } catch (err) {
      showErrorNotification(`[fetchMomentList] ${err.message}`);
    } finally {
      this.loading = false;
    }
  };

  @action public selectMomentoSlide = (slide: number) => {
    this.selectedMomentoSlide = slide;
    this.setAspectRatioWithStore();
  };

  /**
   * Sets editor's aspect ratio to the saved aspect ratio
   * it will search saved aspect as following for: current momento -> current moment -> global
   * If nothing found in store sets default aspect ratio
   * @param slide number of slide or 0 if none selected
   */
  @action setAspectRatioWithStore = () => {
    const savedCropData = StorageServiceInstance.getImageCropData(
      this.selectedMoment._id,
      this.selectedMomentoSlide || 0,
    );
    const newAspect = savedCropData?.aspect || ASPECT_RATIO_LIST["8_10"];
    console.log(`Set editor's aspect ratio to ${newAspect}`);
    this.editorAspect = newAspect;
  };

  @action public changeAspect = (aspect: ASPECT_RATIO_LIST): void => {
    this.editorAspect = aspect;
  };

  private calculateImageStartPosition = (
    cropData: ImageCropData | null,
  ): ImageCoordinates => {
    return calculateImageStartPosition(
      cropData,
      this.centerCoords.x,
      this.centerCoords.y,
      this.defaultZoomWidth,
      this.defaultZoomHeight,
      this.offsetY,
    );
  };

  private calculateImageSize = (cropData: ImageCropData | null): ImageSize => {
    return calculateImageSize(
      cropData,
      this.defaultZoomWidth,
      this.defaultZoomHeight,
    );
  };

  /**
   * Returns the position and size of the image to be displayed on canvas
   * Calculate offset of the image on the canvas
   * depending on the aspect ratio of canvas and cropped image
   * @param canvasWidth width of the canvas element
   * @param canvasHeight height of the canvas element
   * @param momentId id of the moment we are searching crop data for
   * @param momentoIndex index of the momentO we are searching crop data for
   */
  public getCanvasImagePosition = (
    _canvasWidth: number,
    _canvasHeight: number,
    momentId: string,
    momentoIndex: number,
  ): CanvasPositionData => {
    const {
      width: canvasWidth,
      height: canvasHeight,
      x: areaOffsetX,
      y: areaOffsetY,
    } = getViewAreaPosition(
      _canvasWidth,
      _canvasHeight,
      ASPECT_RATIO_LIST["8_10"],
    );

    const savedCropData = StorageServiceInstance.getImageCropData(
      momentId,
      momentoIndex,
    );

    const { x: startXPosition, y: startYPosition } = this.calculateImageStartPosition(savedCropData);

    const { width: imageWidth, height: imageHeight } = this.calculateImageSize(savedCropData);

    // currently canvas is a square preview, need to decrease the bigger image side to fit area
    return this.getCanvasSquareImagePosition(
      {
        x: startXPosition,
        y: startYPosition,
        width: imageWidth,
        height: imageHeight,
      },
      areaOffsetX,
      areaOffsetY,
      canvasWidth,
    );
  };

  /**
   * Special case of `getCanvasImagePosition` when canvas is a square
   * Method cuts off the larger side of the image to make it square
   * @param imageData image size and position
   * @param areaOffsetX view area offset
   * @param areaOffsetY view area offset
   * @param viewAreaSize view area size (width = height)
   */
  private getCanvasSquareImagePosition = (
    imageData: ImageCropData,
    areaOffsetX: number,
    areaOffsetY: number,
    viewAreaSize: number,
  ): CanvasPositionData => {
    const imageRatio = imageData.height / imageData.width;
    if (imageData.height > imageData.width) {
      const newCanvasWidth = viewAreaSize / imageRatio;
      return {
        x: imageData.x,
        y: imageData.y,
        width: imageData.width,
        height: imageData.height,
        canvasXOffset: areaOffsetX + (viewAreaSize - newCanvasWidth) / 2.0,
        canvasYOffset: areaOffsetY,
        canvasWidth: newCanvasWidth,
        canvasHeight: viewAreaSize,
      };
    }
    if (imageData.height < imageData.width) {
      const newCanvasHeight = viewAreaSize * imageRatio;
      return {
        x: imageData.x,
        y: imageData.y,
        width: imageData.width,
        height: imageData.height,
        canvasXOffset: areaOffsetX,
        canvasYOffset: areaOffsetY + (viewAreaSize - newCanvasHeight) / 2.0,
        canvasWidth: viewAreaSize,
        canvasHeight: newCanvasHeight,
      };
    }

    // cropped image is already a square
    return {
      ...imageData,
      canvasXOffset: areaOffsetX,
      canvasYOffset: areaOffsetY,
      canvasWidth: viewAreaSize,
      canvasHeight: viewAreaSize,
    };
  };

  /**
   * Fetches the specific coordinates and sizes of the image
   * Is used while displaying in editor and downloading images
   * @param momentId if provided, will search crop data for this moment,
   * otherwise for currently selected moment
   * @param momentoSlide if provided, will search crop data for this momento,
   * otherwise for momento with index 0
   */
  public getEditorImagePosition = (
    momentId?: string,
    momentoSlide?: number,
  ): ImageCropData => {
    const savedCropData = StorageServiceInstance.getImageCropData(
      this.getMomentId(momentId),
      this.getMomentoIndex(momentoSlide),
    );

    const { x, y } = this.calculateImageStartPosition(savedCropData);

    const { width, height } = this.calculateImageSize(savedCropData);

    return { x, y, width, height };
  };

  /**
   * Saves crop data inside the worker storage depending on the provided type
   * @param cropData position, size and aspect ratio data
   * @param type type of saving that we perform
   * `momento` - saves crop data only for currently selected momentO
   * `moment` - saves crop data for currently selected moment
   * `global` - saves crop data as global for all moments and momento of current game and seat
   */
  public saveEditorChanges = (
    cropData: ImageCropData,
    type: EditorSaveType,
  ): void => {
    switch (type) {
      case "global":
        StorageServiceInstance.saveImageCropData(cropData);
        if (this.isPregenerateGIFApp()) {
          this.reRenderAllGIFs();
        }
        break;
      case "moment":
        StorageServiceInstance.saveImageCropData(
          cropData,
          this.selectedMoment._id,
        );

        if (this.isPregenerateGIFApp()) {
          this.reRenderSingleGIF(this.selectedMoment._id);
        }
        break;
      case "momento":
        StorageServiceInstance.saveImageCropData(
          cropData,
          this.selectedMoment._id,
          this.selectedMomentoSlide || 0,
        );
        if (this.isPregenerateGIFApp()) {
          this.reRenderSingleGIF(this.selectedMoment._id);
        }
        break;
      default:
        showErrorNotification("Wrong saving options");
    }
  };

  /**
   * Deletes crop data inside the worker storage depending on the provided type
   * @param type type of deletion that we perform
   * `momento` - deletes crop data only for currently selected momentO
   * `moment` - deletes crop data for currently selected moment,
   *    changes with `momento` type won't be deleted
   * `global` - deletes all global crop data, crop data for `momento` or `moment` won't be deleted
   */
  public deleteEditorChanges = (type: EditorSaveType): void => {
    switch (type) {
      case "global":
        StorageServiceInstance.removeImageCropData();
        if (this.isPregenerateGIFApp()) {
          this.reRenderAllGIFs();
        }
        break;
      case "moment":
        StorageServiceInstance.removeImageCropData(this.selectedMoment._id);
        if (this.isPregenerateGIFApp()) {
          this.reRenderSingleGIF(this.selectedMoment._id);
        }
        break;
      case "momento":
        if (!this.selectedMomentoSlide && this.selectedMomentoSlide !== 0) {
          AntdMessage.error(
            "No momento selected. Try to get back and select a new one",
          );
          return;
        }
        StorageServiceInstance.removeImageCropData(
          this.selectedMoment._id,
          this.selectedMomentoSlide,
        );
        if (this.isPregenerateGIFApp()) {
          this.reRenderSingleGIF(this.selectedMoment._id);
        }
        break;
      default:
        showErrorNotification("Wrong delete options");
    }
  };

  /**
   * Returns sponsorImage for selected moment,
   */
  public getSponsorOverlayForDownload = (): string | undefined => {
    const targetMomentIndex = this.momentList.findIndex(
      (m) => m._id === this.selectedMoment._id,
    );

    const targetMoment = this.momentList[targetMomentIndex];

    if (!targetMoment) {
      AntdMessage.error("Something went wrong while searching for moment");
      throw new Error();
    }

    if (targetMoment.sponsorOverlay && targetMoment.sponsorOverlayImage) {
      return targetMoment.sponsorOverlayImage;
    }

    return undefined;
  };

  /**
   * Returns all data needed for image downloading including cropped image position,
   * link to src image and name for the file
   * @param wholeMoment `true` if we need to load all momento or gif, otherwise `false`
   * @param gif `true` is we need to load gif, otherwise `false`
   */
  public getDownloadDataArray = (
    wholeMoment?: boolean,
    gif?: boolean,
  ): DownloadData[] => {
    const targetMomentIndex = this.momentList.findIndex(
      (m) => m._id === this.selectedMoment._id,
    );

    const targetMoment = this.momentList[targetMomentIndex];

    if (!targetMoment) {
      AntdMessage.error("Something went wrong while searching for moment");
      throw new Error();
    }

    if (!wholeMoment) {
      const position = this.getEditorImagePosition();
      const momentoIndex = this.getMomentoIndex();

      return [
        {
          ...position,
          link: targetMoment.momentoLinks[momentoIndex],
          name: this.generateDownloadName(targetMomentIndex, momentoIndex),
        },
      ];
    }

    return targetMoment.momentoLinks.map((link, index) => {
      const { height, width, x, y } = this.getEditorImagePosition(
        targetMoment._id,
        index,
      );
      return <DownloadData>{
        height,
        width,
        x,
        y,
        link,
        name: this.generateDownloadName(targetMomentIndex, gif ? null : index),
      };
    });
  };

  public getDownloadDataArrayForMomento = (
    momentoIndex: number,
  ): DownloadData[] => {
    const targetMomentIndex = this.momentList.findIndex(
      (m) => m._id === this.selectedMoment._id,
    );

    const targetMoment = this.momentList[targetMomentIndex];

    if (!targetMoment) {
      AntdMessage.error("Something went wrong while searching for moment");
      throw new Error();
    }

    const index = momentoIndex < targetMoment.momentoLinks.length ? momentoIndex : 0;
    const position = this.getEditorImagePosition(targetMoment._id, index);

    return [
      {
        ...position,
        link: targetMoment.momentoLinks[index],
        name: this.generateDownloadName(targetMomentIndex, index),
      },
    ];
  }

  /**
   * Returns all data needed for image downloading including cropped image position,
   * link to src image and name for the file
   * @param selectedIndexes selected indexes
   */
  public getDownloadSelectedMomentosArray = (
    selectedIndexes: SelectedMomentosData,
  ): DownloadData[] => {
    const targetMomentIndex = this.momentList.findIndex(
      (m) => m._id === this.selectedMoment._id,
    );

    const targetMoment = this.momentList[targetMomentIndex];

    if (!targetMoment) {
      AntdMessage.error("Something went wrong while searching for moment");
      throw new Error();
    }

    const result: DownloadData[] = [];
    for (
      let index = 0, len = targetMoment.momentoLinks.length;
      index < len;
      index += 1
    ) {
      const link = targetMoment.momentoLinks[index];
      if (selectedIndexes[index]) {
        const { height, width, x, y } = this.getEditorImagePosition(
          targetMoment._id,
          index,
        );
        result.push({
          height,
          width,
          x,
          y,
          link,
          name: this.generateDownloadName(targetMomentIndex, index),
        });
      }
    }

    return result;
  };

  /**
   * Generating file name that will be assigned to
   * the file during donwload
   * Sample name: "Lexington_Legends_vs_New_Team_06_15_2021_m0_0"
   * @param momentIndex index of the moment in moment list received from the server
   * @param momentoIndex index of the momento in the selected moment
   * or null if it shouldn't be specified in name, e.g. when gif is being donwloaded
   */
  private generateDownloadName = (
    momentIndex: number,
    momentoIndex: number = null,
  ): string => {
    const team1Name = StorageServiceInstance.selectedGameData.team1.name.replace(" ", "_");
    const team2Name = StorageServiceInstance.selectedGameData.team2.name.replace(" ", "_");

    const gameDate = getGameStartDate(
      StorageServiceInstance.selectedGameData.startDate,
    );

    const baseName = `${team1Name}_vs_${team2Name}_${gameDate}_m${momentIndex}`;

    return momentoIndex !== null ? `${baseName}_${momentoIndex}` : baseName;
  };

  private getMomentId = (momentId?: string): string => {
    return momentId || this.selectedMoment._id;
  };

  public getMomentoIndex = (momentoIndex?: number): number => {
    return momentoIndex || this.selectedMomentoSlide || 0;
  };

  private dependenciesLoaded = (): boolean => {
    return (
      !!StorageServiceInstance.selectedGameData
      && !!StorageServiceInstance.selectedSeat
    );
  };

  private schedule = (func: () => void, time = 300): NodeJS.Timeout => {
    return setTimeout(() => {
      func();
    }, time);
  };

  public getCroppedVideo = async (
    request: GetCroppedVideoRequest,
  ): Promise<any> => {
    // if (!this.dependenciesLoaded()) {
    //   this.schedule(this.fetchMomentList);
    //   return;
    // }
    let result = null;
    try {
      result = await getCroppedVideo(request);
      console.log(
        `MomentService.getCroppedVideo() - received ${result.length} bytes.`,
      );
    } catch (err) {
      showErrorNotification(`[getCroppedVideo] ${err.message}`);
    }
    return result;
  };

  // old code, can be necessary in the future
  /**
   * Returns the position and size of the image to be displayed on canvas
   * Calculate offset of the image on the canvas
   * depending on the aspect ratio of canvas and cropped image
   * @param canvasWidth width of the canvas element
   * @param canvasHeight height of the canvas element
   * @param momentId id of the moment we are searching crop data for
   * @param momentoIndex index of the momentO we are searching crop data for
   */
  public getCanvasImagePositionCropImage_Old = (
    _canvasWidth: number,
    _canvasHeight: number,
    momentId: string,
    momentoIndex: number,
  ): CanvasPositionData => {
    const {
      width: canvasWidth,
      height: canvasHeight,
      x: areaOffsetX,
      y: areaOffsetY,
    } = getViewAreaPosition(
      _canvasWidth,
      _canvasHeight,
      ASPECT_RATIO_LIST["8_10"],
    );

    const canvasAspect = canvasWidth / canvasHeight;

    const savedCropData = StorageServiceInstance.getImageCropData(
      momentId,
      momentoIndex,
    );

    const imageAspect = savedCropData?.aspect || ASPECT_RATIO_LIST["8_10"];

    const { x: startXPosition, y: startYPosition } = this.calculateImageStartPosition(savedCropData);

    const { width: imageWidth, height: imageHeight } = this.calculateImageSize(savedCropData);

    // canvas is a square preview, need to decrease the bigger image side to fit area
    if (canvasAspect === 1) {
      return this.getCanvasSquareImagePositionCropImage_Old(
        {
          x: startXPosition,
          y: startYPosition,
          width: imageWidth,
          height: imageHeight,
        },
        areaOffsetX,
        areaOffsetY,
      );
    }

    // the height of the canvas is excesive, need to add blank spaces at the top and bottom
    if (canvasAspect > imageAspect) {
      // expected width with this aspect ratio and height
      const expectedWidth = canvasHeight * imageAspect;
      const extraCanvasWidth = canvasWidth - expectedWidth;
      const canvasXOffset = extraCanvasWidth / 2;

      return {
        x: startXPosition,
        y: startYPosition,
        width: imageWidth,
        height: imageHeight,
        canvasYOffset: areaOffsetY,
        canvasXOffset: canvasXOffset + areaOffsetX,
        canvasWidth: 0,
        canvasHeight: 0,
      };
    }

    // the width of the canvas is excesive, need to add blank spaces to left and right
    if (canvasAspect < imageAspect) {
      // expected height with this aspect ratio and width
      const expectedHeight = canvasWidth / imageAspect;
      const extraCanvasHeight = canvasHeight - expectedHeight;
      const canvasYOffset = extraCanvasHeight / 2;

      return {
        x: startXPosition,
        y: startYPosition,
        width: imageWidth,
        height: imageHeight,
        canvasYOffset: canvasYOffset + areaOffsetY,
        canvasXOffset: areaOffsetX,
        canvasWidth: 0,
        canvasHeight: 0,
      };
    }

    // canvas and image aspect ratio is perfect match
    return {
      x: startXPosition,
      y: startYPosition,
      width: imageWidth,
      height: imageHeight,
      canvasYOffset: areaOffsetY,
      canvasXOffset: areaOffsetX,
      canvasWidth: 0,
      canvasHeight: 0,
    };
  };

  // old code, can be necessary in the future
  /**
   * Special case of `getCanvasImagePosition` when canvas is a square
   * Method cuts off the larger side of the image to make it square
   * @param imageData image size and position
   * @param areaOffsetX view area offset
   * @param areaOffsetY view area offset
   */
  private getCanvasSquareImagePositionCropImage_Old = (
    imageData: ImageCropData,
    areaOffsetX: number,
    areaOffsetY: number,
  ): CanvasPositionData => {
    // image height is too big, need to cut chunks top and bottom
    if (imageData.height > imageData.width) {
      const extraHeight = imageData.height - imageData.width;
      return {
        x: imageData.x,
        y: imageData.y + extraHeight / 2,
        width: imageData.width,
        height: imageData.height - extraHeight,
        canvasXOffset: areaOffsetX,
        canvasYOffset: areaOffsetY,
        canvasWidth: 0,
        canvasHeight: 0,
      };
    }
    // image  width is too big, need to cut chunks from left and right
    if (imageData.height < imageData.width) {
      const extraWidth = imageData.width - imageData.height;
      return {
        x: imageData.x + extraWidth / 2,
        y: imageData.y,
        width: imageData.width - extraWidth,
        height: imageData.height,
        canvasXOffset: areaOffsetX,
        canvasYOffset: areaOffsetY,
        canvasWidth: 0,
        canvasHeight: 0,
      };
    }
    // cropped image is already a square
    return {
      ...imageData,
      canvasXOffset: areaOffsetX,
      canvasYOffset: areaOffsetY,
      canvasWidth: 0,
      canvasHeight: 0,
    };
  };

  @action public setGIFPlaying = (playing: boolean) => {
    this.gifPlaying = playing;
    this.gifImageToPlay = null;
  };

  @action public setGIFLoading = (loading: boolean) => {
    this.gifLoading = loading;
  };

  @action public setGIFImageToPlay = (image: string | null) => {
    this.gifImageToPlay = image;
  };

  @action public setSelectedMomentoForMoment = (
    momentId: string,
    selected: number,
  ) => {
    this.momentsData = this.momentsData.map((moment) => {
      return moment._id === momentId
        ? { ...moment, selectedMomentoSlide: selected }
        : moment;
    });
  };

  @action public setMomentGIFPlaying = (
    momentId: string,
    gifPlaying: boolean,
  ) => {
    this.momentsData = this.momentsData.map((moment) => {
      return moment._id === momentId
        ? { ...moment, gifPlaying, gifImageToPlay: null }
        : moment;
    });
  };

  @action public setMomentGIFLoading = (
    momentId: string,
    gifLoading: boolean,
  ) => {
    this.momentsData = this.momentsData.map((moment) => {
      return moment._id === momentId ? { ...moment, gifLoading } : moment;
    });
  };

  @action public setMomentGIFImageToPlay = (
    momentId: string,
    setGIFImageToPlay: string | null,
  ) => {
    this.momentsData = this.momentsData.map((moment) => {
      return moment._id === momentId
        ? { ...moment, setGIFImageToPlay }
        : moment;
    });
  };

  @action public setMomentIdToScroll = (id: string) => {
    this.momentIdToScroll = id;
  };

  @action private reRenderSingleGIF = (momentId: string) => {
    this.momentsData = this.momentsData.map((mData) => {
      return mData._id === momentId
        ? {
          ...mData,
          gifLoading: true,
          gifImageToPlay: null,
        }
        : mData;
    });

    this.cacheService.renderSingleGIF(momentId);
  };

  @action private reRenderAllGIFs = () => {
    this.momentsData = this.momentsData.map((mData) => {
      return {
        ...mData,
        gifLoading: true,
        gifImageToPlay: null,
      };
    });

    this.cacheService.renderAllGIFs();
  };

  @action public showShareDialog = (files, type) => {
    this.shareFiles = files;
    this.shareType = type;
    this.shareDialogIsVisible = true;
  }

  @action public hideShareDialog = () => {
    this.shareFiles = [];
    this.shareType = "";
    this.shareDialogIsVisible = false;
  }

  public isPregenerateGIFApp = () => {
    return this.DEBUG_APP_TYPE === "pregenGIF";
  }

  public isPlayImagesAsGIFApp = () => {
    return this.DEBUG_APP_TYPE === "";
  }

  public setPregenerateGIFAppType = (setMode = true) => {
    this.DEBUG_APP_TYPE = setMode ? "pregenGIF" : "";
  }

  @action public setStartingMomentIndex = async (index) => {
    if (this.momentList?.length > 0) {
      await this.fetchMomentListPart(index);
    }
    this.startingMomentIndex = index;
  }

  public fetchSpecialSectionImageData = async () => {
    if (this.specialSectionImageLoading || !StorageServiceInstance.selectedGameData) return;

    this.specialSectionImageInfo = [];
    this.specialSecitonImageCompressRate = 1.0;
    this.specialSectionImageLoading = true;

    try {
      const request: GetGameMomentsRequests = this.generateMomentRequest(false, true);

      const { data, error } = await fetchGameMomentsRequest(request);

      const sortedMoments = data.moments.sort(this.compareMoments);

      if (error) {
        this.specialSectionImageInfo = [];
        this.specialSecitonImageCompressRate = 1.0;
        AntdMessage.warn(error);
      } else {
        this.specialSectionImageInfo = sortedMoments;
        this.specialSecitonImageCompressRate = request.resizeWidth ? request.compressRate : undefined;
      }
    } catch (err) {
      showErrorNotification(`[fetchMomentList] ${err.message}`);
    } finally {
      this.specialSectionImageLoading = false;
    }
  };

  // experimental
  public payForTheGame = async (email, gameId) => {
    const result = await payForTheGame(email, gameId);
    // reload moments
    this.fetchMomentList();
  }

  public addPendingPayment = async (email, gameId) => {
    const result = await addPendingPayment(email, gameId);
  }

  // move to the previous session
  @action public moveToThePreviousSession = async (previousSession: UniqueUser) => {
    if (!previousSession) {
      return;
    }

    StorageServiceInstance.savePreviousSessionToStorage(previousSession);
    await updateLastSessionDate(previousSession._id);

    this.setMomentIdToScroll(null);
    this.setStartingMomentIndex(0);
    AppServiceInstance.history.push(Routes.MY_MOMENTS);
  }

  // add a purchase link and return its id
  public addPurchaseLink = async (
    momentId: string,
    momentoIndex: number,
    imageLink: string,
    width?: number,
    height?: number,
  ) => {
    const moment = this.momentList.find(
      (m) => m._id === momentId,
    );

    if (!moment || momentoIndex < 0 || moment.momentoLinks.length <= momentoIndex || !imageLink) {
      return "";
    }

    // const url = moment.momentoLinks[momentoIndex];

    const EMPTY_EMAIL = "no-reply@momento.com";
    const response = await addPurchaseLink({
      url: imageLink,
      email: StorageServiceInstance.selectedEmail || EMPTY_EMAIL,
      width: width || this.returnedMomentWidth,
      height: height || this.returnedMomentHeight,
      isSpecialSection: StorageServiceInstance.selectedSectionIsSpecial,
      section: StorageServiceInstance.selectedSectionIsSpecial
        ? StorageServiceInstance.selectedSpecialSection : StorageServiceInstance.selectedSection,
      row: StorageServiceInstance.selectedSectionIsSpecial
        ? "" : StorageServiceInstance.selectedRow,
      seat: StorageServiceInstance.selectedSectionIsSpecial
        ? "" : StorageServiceInstance.selectedSeat,
      gameId: StorageServiceInstance.selectedGame,
      momentId,
      momentoIndex,
      caption: moment.text || "Momento",
    });

    return response;
  }

  // add a new print order and return its number
  public reservePrintOrderNumber = async (
  ) => {
    const response = await reservePrintOrderNumber(StorageServiceInstance.selectedGame);

    return { number: response, gameId: StorageServiceInstance.selectedGame };
  }

  // emulate get image request
  public testGetPurchasedImage = async (imageId: string, ticketId: string) => {
    const imageInfo = await getPurchasedImage(imageId, ticketId, StorageServiceInstance.selectedEmail);
    return imageInfo;
  }

  // performs necessary saving and moves router to the next page
  @action public moveToTheNextPage = (id: string) => {
    const nextPageId = StorageServiceInstance.getNextPage(id);
    // additional preparations and current user save
    if (nextPageId === Routes.MY_MOMENTS) {
      if (!StorageServiceInstance.selectedGameData?.checkLastName) {
        StorageServiceInstance.saveUserDetailsToDatabase();
      }
      this.fetchMomentList();
      this.setMomentIdToScroll(null);
      this.setStartingMomentIndex(0);
    }

    StorageServiceInstance.moveToTheNextPage(id);
  }

  // moves router to the previous page
  @action public moveToThePreviousPage = (id: string) => {
    StorageServiceInstance.moveToThePreviousPage(id);
  }
}

export const MomentServiceInstance = new MomentService();

export const MomentServiceContext = createContext(MomentServiceInstance);
